import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MainComponent} from "./main/main.component";
import {PartnerSliderComponent} from "../module/partner-slider/partner-slider.component";
import {AdvantagesComponent} from "../module/advantages/advantages.component";
import { AboutComponent } from './about/about.component';
import { PartnersComponent } from './partners/partners.component';
import { EventsComponent } from './events/events.component';
import { EventsDetailComponent } from './events-detail/events-detail.component';
import { MediaComponent } from './media/media.component';
import {EventCardModule} from "../module/event-card/event-card.module";
import {UiModule} from "../ui/ui.module";
import {RouterLink} from "@angular/router";
import { SectionComponent } from '../components/section/section.component';
import { EmblaSliderComponent } from '../module/embla-slider/embla-slider.component';
import {ReviewItemComponent} from "../components/review-item/review-item.component";
import {QuestionBoxComponent} from "../components/question-box/question-box.component";
import { FramePageComponent } from '../components/frame-page/frame-page.component';
import { FaqComponent } from './faq/faq.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { OrganizersComponent } from './organizers/organizers.component';
import { PoliticalComponent } from './political/political.component';
import {PaginationComponent} from "../components/pagination/pagination.component";
import { FilesComponent } from '../components/files/files.component';
import { SocialComponent } from '../components/social/social.component';
import { DeclinsionPipe } from '../pipe/declinsion.pipe';
import {Error404Component} from "./404/404.component";
import {TranslateModule} from "@ngx-translate/core";
import {GallerySliderComponent} from "../components/gallery-slider/gallery-slider.component";



@NgModule({
  declarations: [
    MainComponent,
    AboutComponent,
    PartnersComponent,
    EventsComponent,
    EventsDetailComponent,
    MediaComponent,
    FaqComponent,
    ReviewsComponent,
    OrganizersComponent,
    PoliticalComponent,
    Error404Component,
  ],
    imports: [
        CommonModule,
        PartnerSliderComponent,
        AdvantagesComponent,
        EventCardModule,
        UiModule,
        RouterLink,
        SectionComponent,
        EmblaSliderComponent,
        ReviewItemComponent,
        QuestionBoxComponent,
        FramePageComponent,
        PaginationComponent,
        FilesComponent,
        SocialComponent,
        DeclinsionPipe,
        TranslateModule,
        GallerySliderComponent,
    ],
})
export class PagesModule { }
