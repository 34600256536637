<div class="bg" (click)="close()"></div>
<div class="custom-modal">
  <div class="content">
    <div class="close" (click)="close()"><div class="icon-close"></div></div>
    <div class="content-wrapper">
      <ng-container *ngIf="type === 'reg'">
        <div>
          <h2 class="h3 auth-title-section-modal">{{ 'modal.title.registration' | translate }}</h2>
          <div class="pretitle"><span class="warning">*</span> {{ 'modal.text.codeConfirmSendEmail' | translate }}</div>
        </div>
        <form class="fiels-list" [formGroup]="formReg">
          <ul class="field-list">
            <li class="field-list__item">
              <ui-input [label]="'inputs.label.fio' | translate" formControlName="title" [Mcontrol]="getControlReg('title')" [isRequired]="true" type="text" [placeholder]="'inputs.label.fioPlaceholder' | translate"></ui-input>
            </li>
            <li class="field-list__item">
              <ui-input [label]="'inputs.label.city' | translate" formControlName="city" [Mcontrol]="getControlReg('city')" [isRequired]="true" type="text" [placeholder]="'inputs.label.cityPlaceholder' | translate"></ui-input>
            </li>
            <li class="field-list__item">
              <ui-input label="Email" formControlName="email" [Mcontrol]="getControlReg('email')" [isRequired]="true" type="email" placeholder="ivan@company.ru"></ui-input>
            </li>
            <li class="field-list__item">
              <ui-input-phone-ru
                [langInput]="'lang' | translate"
                [label]="'inputs.label.phone' | translate" formControlName="phone" [Mcontrol]="getControlReg('phone')" [formControl]="getControlReg('phone')" type="phone" [placeholder]="'inputs.label.phone' | translate"></ui-input-phone-ru>
              <!--<ui-input-phone label="Телефон" formControlName="phone" [formControl]="getControlReg('phone')" type="phone" placeholder="Телефон"></ui-input-phone>-->
            </li>
            <li class="field-list__item">
              <ui-input [label]="'inputs.label.password' | translate" formControlName="password" [Mcontrol]="getControlReg('password')" [isRequired]="true" type="password" [placeholder]="'inputs.label.password' | translate"></ui-input>
            </li>
            <li class="field-list__item">
              <ui-input [label]="'inputs.label.password' | translate" formControlName="password2" [Mcontrol]="getControlReg('password2')" [isRequired]="true" type="password" [placeholder]="'inputs.label.confirmPassword' | translate"></ui-input>
            </li>
            <li class="field-list__item field-list__item--checkbox">
              <div class="field-checkbox">
                <label>
                  <input type="checkbox" formControlName="confirm">
                  <span class="caption">{{ 'other.political' | translate }} <a (click)="navigateUrl('political')">{{ 'other.politicalAction' | translate }}</a></span>
                </label>
              </div>
            </li>
          </ul>
          <ui-button type="primary" [isDisabled]="formReg.invalid" (click)="formReg.valid && submitReg()">{{ 'btn.registration' | translate }}</ui-button>
          <div class="error-server" *ngFor="let item of errorServer">{{item}}</div>
        </form>
        <p>{{ 'modal.text.accOn' | translate }} <span (click)="switchType('auth')">{{ 'modal.text.enterToEmail' | translate }}</span></p>
      </ng-container>
      <ng-container *ngIf="type === 'auth'">
        <h2 class="h3 auth-title-section-modal">{{ 'btn.enter' | translate }}</h2>

        <form class="fiels-list" [formGroup]="formLogin">
          <ul class="field-list">
            <li class="field-list__item">
              <ui-input label="Email" formControlName="email" [Mcontrol]="getControlLogin('email')" [isRequired]="true" type="email" placeholder="ivan@company.ru"></ui-input>
            </li>
            <li class="field-list__item">
              <ui-input [label]="'inputs.label.password' | translate" formControlName="password" [Mcontrol]="getControlLogin('password')" type="password" [placeholder]="'inputs.label.password' | translate"></ui-input>
            </li>
          </ul>
          <ui-button type="primary" [isDisabled]="formLogin.invalid" (click)="formLogin.valid && submitLogin()">{{ 'btn.enter' | translate }}</ui-button>
          <div class="error-server" *ngFor="let item of errorServer">{{item}}</div>
        </form>
        <div class="actions">
          <div class="action">
            <div>{{ 'modal.text.noAcc' | translate }} <a (click)="switchType('reg')">{{ 'btn.registration' | translate }}</a></div>
          </div>
          <div class="action">
            <a (click)="switchType('recovery')">{{ 'modal.title.recoveryEnter' | translate }}</a>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="type === 'code'">
        <h2 class="h3 auth-title-section-modal" [innerHTML]="'modal.title.confirmRegistration' | translate"></h2>
        <div>{{ 'modal.text.onEmail' | translate }} <a href="mailto:{{ emailSend }}">{{ emailSend }}</a> <span [innerHTML]="'modal.text.sendActionCode' | translate"></span></div>
        <form class="fiels-list">
          <ul class="field-list">
            <li class="field-list__item">
              <ui-input [label]="'inputs.label.code' | translate" [formControl]="code" [Mcontrol]="code" [isRequired]="true" type="text" [placeholder]="'inputs.label.codeConfirm' | translate"></ui-input>
            </li>
          </ul>
          <ui-button type="primary" [isDisabled]="code.invalid" (click)="code.valid && submitCode()">{{ 'btn.confirm' | translate }}</ui-button>
          <div class="error-server" *ngFor="let item of errorServer">{{item}}</div>
        </form>
        <div class="actions">
          <div class="action">
            <ui-timer-button
              [btnText]="'modal.text.sendNewCode' | translate"
              [repeaterTimer$]="repeatTimer$"
              (emitClick)="repeatCode()"
              [timeTimer]="120"></ui-timer-button>
          </div>
          <div class="action">
            <a (click)="switchType('reg')">{{ 'modal.text.editEmail' | translate }}</a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="type === 'recovery'">
        <ng-container *ngIf="!emailRecoverySend">

          <h2 class="h3 auth-title-section-modal">{{ 'modal.title.recoveryEnter' | translate }}</h2>
          <div [innerHTML]="'modal.text.pretitleRecovery' | translate"></div>
          <form class="fiels-list">
            <ul class="field-list">
              <li class="field-list__item">
                <ui-input label="Email" [formControl]="emailRecovery" [Mcontrol]="emailRecovery" [isRequired]="true" type="email" placeholder="ivan@company.ru"></ui-input>
              </li>
            </ul>
            <ui-button type="primary" [isDisabled]="emailRecovery.invalid" (click)="emailRecovery.valid && submitRecovery()">{{ 'btn.recovery' | translate }}</ui-button>
            <div class="error-server" *ngFor="let item of errorServer">{{item}}</div>
          </form>
          <div class="actions">
            <div class="action">
              <div>{{ 'modal.text.noAcc' | translate }} <a (click)="switchType('reg')">{{ 'btn.registration' | translate }}</a></div>
            </div>
            <div class="action">
              <a (click)="switchType('auth')">{{ 'modal.text.iRemember' | translate }}</a>
            </div>
          </div>

        </ng-container>
        <ng-container *ngIf="emailRecoverySend">

          <h2 class="h3 auth-title-section-modal">{{ 'modal.title.readys' | translate }}</h2>
          <div>{{ 'modal.text.onEmail' | translate }} <a href="mailto:{{emailRecovery.value}}">{{emailRecovery.value}}</a> {{ 'modal.text.sendAction' | translate }}</div>
          <ui-button type="primary" (click)="close()">{{ 'btn.great' | translate }}</ui-button>
          <div class="actions">
            <div class="action">
              <ui-timer-button
                [repeaterTimer$]="repeatTimer$"
                (emitClick)="repeatCodeRecovery()"
                [btnText]="'modal.text.sendRepeat' | translate"
                [timeTimer]="120"></ui-timer-button>
            </div>
            <div class="action">
              <a (click)="switchType('reg')">{{ 'modal.text.editEmail' | translate }}</a>
            </div>
          </div>

        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
