import {AfterContentChecked, AfterViewInit, Component, Inject, Input, Self, ViewChild} from '@angular/core';
import {
  EmblaCarouselDirective,
  EmblaCarouselType,
  EmblaEventType
} from 'embla-carousel-angular'
import {NgForOf, NgIf} from "@angular/common";
import {EmblaOptionsType} from "embla-carousel";
import ClassNames from 'embla-carousel-class-names'
import {UiModule} from "../../ui/ui.module";
import {interval, Observable, takeUntil} from 'rxjs';
import {DestroyService} from "../../service/destroy.service";

@Component({
  selector: 'app-embla-slider',
  standalone: true,
  imports: [EmblaCarouselDirective, NgIf, NgForOf, UiModule],
  templateUrl: './embla-slider.component.html',
  styleUrl: './embla-slider.component.scss',
  providers: [DestroyService]
})
export class EmblaSliderComponent implements AfterViewInit, AfterContentChecked {
  @ViewChild(EmblaCarouselDirective) emblaRef!: EmblaCarouselDirective;
  @Input() arrowVisible: boolean = true;
  @Input() autoplay = 0;
  @Input() options!: EmblaOptionsType;
  plugins = [ClassNames()];

  private emblaApi?: EmblaCarouselType;
  prevDisabled = false;
  nextDisabled = false;
  public readonly subscribeToEvents: EmblaEventType[] = [
    'init',
    'pointerDown',
    'pointerUp',
    'slidesChanged',
    'slidesInView',
    'select',
    'settle',
    'destroy',
    'reInit',
    'resize',
    'scroll'
  ];

  onEmblaChanged(event: EmblaEventType): void {
    // console.log(`Embla event triggered: ${event}`)
  }
  constructor(@Self() @Inject(DestroyService) private destroy$: Observable<void>) {
  }

  ngAfterViewInit() {
    this.emblaApi = this.emblaRef.emblaApi;
    if (this.autoplay) {
      interval(this.autoplay).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.emblaApi?.scrollNext();
      })
    }
  }
  ngAfterContentChecked() {
    this.checkNavButtonState();
  }

  nextSlide() {
    this.emblaApi?.scrollNext();
    this.checkNavButtonState();
  }
  prevSlide() {
    this.emblaApi?.scrollPrev();
    this.checkNavButtonState();
  }

  checkNavButtonState() {
    this.prevDisabled = !this.emblaApi?.canScrollPrev();
    this.nextDisabled = !this.emblaApi?.canScrollNext();
  }
}
