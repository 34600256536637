<main class="main" [class.headerNotFix]="this.headerNotFix | async">
  <app-header></app-header>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>

  <app-auth-modal [type]="(authModal.type$ | async) || 'auth'" *ngIf="authModal.isOpen$ | async"></app-auth-modal>
  <app-gallery-image *ngIf="galleryService.stateVisible | async" [moderation]="true"></app-gallery-image>
  <app-cookie-req [class.active]="activeCookie" (reqCookie)="req()"></app-cookie-req>
  <app-recovery-password-main-modal *ngIf="authModal.isOpenRecoveryPasswordModal$ | async"></app-recovery-password-main-modal>

</main>


