import {Component, Inject, Input, OnInit, Self} from '@angular/core';
import { UiModule } from '../../ui/ui.module';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import {AuthModalService} from "../../service/auth-modal.service";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";
import {ApiService} from "../../service/api.service";
import {IRegisterRequest} from "../../types/request/auth.request";
import {UIInputPhoneRuComponent} from "../ui-input-phone-ru/ui-input-phone-ru.component";
import {UIInputComponent} from "../ui-input/ui-input.component";
import {UIInputPhoneModule} from "../ui-input-phone/ui-input-phone.module";
import {AuthService} from "../../service/auth.service";
import {validatePhoneNumberLength15} from "../ui-input-phone-ru/phone-number-length.validator";
import {UiTimerButtonModule} from "../ui-timer-button/ui-timer-button.module";
import {Observable, Subject, takeUntil} from "rxjs";
import {TranslateModule} from "@ngx-translate/core";
import {DestroyService} from "../../service/destroy.service";

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  standalone: true,
  imports: [
    UiModule,
    NgIf,
    NgForOf,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    UIInputPhoneRuComponent,
    UIInputComponent,
    UIInputPhoneModule,
    UiTimerButtonModule,
    TranslateModule,
    AsyncPipe,
  ],
  providers: [DestroyService],
  styleUrls: [ './auth-modal.component.scss' ],
})
export class AuthModalComponent implements OnInit {

  @Input() type: 'reg' | 'auth' | 'code' | 'recovery' = 'auth';

  repeatTimer$: Subject<void> = new Subject();

  formLogin = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  formReg = new FormGroup({
    title: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [validatePhoneNumberLength15]),
    password: new FormControl('', [Validators.required]),
    password2: new FormControl('', [Validators.required]),
    confirm: new FormControl(false, [Validators.required, Validators.requiredTrue]),
  });
  user_id!: number;
  code = new FormControl('', [Validators.required]);
  errorServer = [];
  emailSend = '';
  emailRecovery = new FormControl('', [Validators.required, Validators.email]);
  emailRecoverySend = false;
  emailRecoveryValue = '';

  constructor(private authModalService: AuthModalService,
              private apiService: ApiService,
              private authService: AuthService,
              private router: Router,
              @Self() @Inject(DestroyService) private destroy$: Observable<void>) { }

  ngOnInit(): void {

    this.formLogin.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(req => {
      this.errorServer = [];
    });
    this.formReg.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(req => {
      this.errorServer = [];
    });
    this.code.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(req => {
      this.errorServer = [];
    });
  }



  close() {
    this.emailSend = '';
    this.authModalService.close();
    this.emailRecovery.setValue('');
    this.errorServer = [];
    this.emailRecoverySend = false;
    this.emailRecoveryValue = '';
    this.emailRecovery.setValue('', {emitEvent: false});
  }

  getControlReg(controlName: string) {
    return this.formReg.get(controlName) as FormControl;
  }
  getControlLogin(controlName: string) {
    return this.formLogin.get(controlName) as FormControl;
  }

  submitLogin() {
    if (this.formLogin.invalid) {
      return;
    }
    this.apiService.authLogin(this.formLogin.getRawValue())
    .subscribe(req => {
       this.authService.setToken(req);
       this.authService.profileInit();
       this.authModalService.close();
       this.errorServer = [];
       this.router.navigate(['/test']);
    }, error => {
       if (error.error.errors) {
         this.errorServer = error.error.errors;
       }
   });
  }

  submitReg() {
    if (this.formReg.invalid) {
      this.formReg.markAllAsTouched();
      this.formReg.updateValueAndValidity();
      return;
    }
    const data = this.formReg.getRawValue() as IRegisterRequest;
    this.apiService.authRegister(data).subscribe(response => {
      this.user_id = response.user_id;
      this.emailSend = data.email;
      // this.repeatCode();
      this.repeatTimer$.next();
      if (true) {
        this.errorServer = [];
        this.authService.setToken(response);
        this.authService.profileInit();
        this.authModalService.close();
        this.router.navigate(['profile/dictation']);
        return;
      }
      this.type = "code";
      this.errorServer = [];
    }, error => {
     if (error.error.errors) {
       this.errorServer = error.error.errors;
     }
   });

  }

  navigateUrl(url: string) {
    this.authModalService.close();
    this.errorServer = [];
    this.router.navigateByUrl(url);
  }

  switchType(reg: 'auth' | 'reg' | 'code' | 'recovery') {
    this.errorServer = [];
    this.type = reg;
  }

  submitCode() {
   if (this.code.invalid || !this.user_id) {
     this.code.markAsTouched();
     this.code.updateValueAndValidity();
     return;
   }
   const code = this.code?.value as string;
   this.apiService.authConfirm({ user_id: this.user_id, confirm_code: code }).subscribe(req => {
     this.authService.setToken(req);
     this.authService.profileInit();
     this.authModalService.close();
     this.errorServer = [];
     this.router.navigate(['profile/dictation']);
   }, error => {
     if (error.error.errors) {
       this.errorServer = error.error.errors;
     }
   });
  }

  submitRecovery() {
    if (this.emailRecovery.invalid) {
      this.emailRecovery.markAsTouched();
      this.emailRecovery.updateValueAndValidity();
      return;
    }
    this.apiService.apiRecoveryPassword(this.emailRecovery.value).subscribe(req => {
      this.repeatTimer$.next();
      this.emailRecoverySend = true;
    }, error => {
     if (error.error.errors) {
       this.errorServer = error.error.errors;
     }
   });
  }

  repeatCode() {
    this.code.setValue('', {emitEvent: false});
    this.apiService.postResendCode(this.user_id).subscribe(req => {
      this.repeatTimer$.next();
    })
  }


  repeatCodeRecovery() {
    if (this.emailRecovery?.value) {
      this.apiService.apiRecoveryPassword(this.emailRecovery.value).subscribe(req => {
        this.repeatTimer$.next();
      });
    }
    return;
  }

}
