<app-frame-page [titlePage]="'pages.partners.title' | translate" class="decorationFull">
  <ng-container>
    <div class="content-wrapper--middle">
      <div class="partners-list">
        <div class="item" *ngFor="let item of partnersList">
          <a [href]="item.url" target="_blank">
            <img *ngIf="!!item.get_thumb" [src]="environment.imageUrl + item.get_thumb" [alt]="item.title">
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</app-frame-page>
