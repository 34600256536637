<ui-button type="secondary" *ngIf="currentPage !== totalPages" (click)="onNextEventMore()">{{ 'paginations.actionMore' | translate }}</ui-button>
<div class="pagination">
  <div class="pagination__wrapper">
    <ui-button type="secondary" class="pagination__button pagination__button--prev" (click)="currentPage > 1 && onPrevious()" [isDisabled]="currentPage === 1">{{ 'paginations.actionPrev' | translate }}</ui-button>
    <ui-button typeButton="slider-action" type="secondary" class="pagination__button pagination__button--mobile pagination__button--prev" (click)="currentPage > 1 && onPrevious()" [isDisabled]="currentPage === 1"></ui-button>
    <nav class="navigation-page">
      <ul class="navigation-list">
        <li class="navigation-list__item" *ngIf="pages[0] > 2">
          <a class="navigation-list__action" (click)="onLeftEllipsisClick()">...</a>
        </li>
        <li class="navigation-list__item" *ngFor="let page of pages">
          <a class="navigation-list__action" (click)="onPageClick(page)" [class.active]="page === currentPage">{{page}}</a>
        </li>
        <li class="navigation-list__item" *ngIf="pages[pages.length - 1] < totalPages - 1">
          <a class="navigation-list__action" (click)="onRightEllipsisClick()">...</a>
        </li>
      </ul>
    </nav>
    <ui-button type="secondary" class="pagination__button pagination__button--next" (click)="currentPage !== totalPages && onNext()" [isDisabled]="currentPage === totalPages">{{ 'paginations.actionNext' | translate }}</ui-button>
    <ui-button typeButton="slider-action" type="secondary" class="pagination__button pagination__button--next pagination__button--mobile" (click)="currentPage !== totalPages && onNext()" [isDisabled]="currentPage === totalPages"></ui-button>
  </div>
</div>
