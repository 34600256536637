import { Injectable } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import {IFaq} from "../types";
@Injectable({
  providedIn: 'root'
})
export class FaqService {
  isOpenFaq: {[key: number]: boolean} = {};

  init(question: IFaq[]) {
    this.isOpenFaq = {};
    question.forEach((x, i) => {
      this.isOpenFaq[x.id] = false;
    });
  }

  setOpen(id: number) {
    for (let key in this.isOpenFaq) {
        if (this.isOpenFaq.hasOwnProperty(key)) {
            this.isOpenFaq[key] = false;
        }
    }
    this.isOpenFaq[id] = true;
  }

}
