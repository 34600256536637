<div class="main-slider">
  <video [loop]="true" [muted]="true" [autoplay]="true" playsinline [controls]="false" (canplay)="videoStart()" [class.hide]="videoNotLoad">
    <source src="./assets/video/desktop.mp4" type="video/mp4" media="(min-width: 721px)" />
    <source src="./assets/video/mobile.mp4" type="video/mp4" media="(max-width: 720px)"  />
    I'm sorry; your browser doesn't support HTML5 video.
  </video>
  <div class="partners bvi-no-styles">
    <ul class="partners-list bvi-no-styles">
      <li class="item bvi-no-styles">
        <div class="item__wrapper bvi-no-styles">
          <div class="caption bvi-no-styles" [innerHTML]="'pages.main.head1' | translate"></div>
          <div class="img bvi-no-styles"><img class="bvi-no-styles" src="./assets/img/atom_city_onecolor.svg" alt=""></div>
        </div>
      </li>
      <li class="item bvi-no-styles">
        <div class="item__wrapper bvi-no-styles">
          <div class="caption bvi-no-styles" [innerHTML]="'pages.main.head2' | translate"></div>
          <div class="img m16 bvi-no-styles"><img class="bvi-no-styles" src="./assets/img/rosatom2.svg" alt=""></div>
        </div>
      </li>
    </ul>
  </div>
  <div class="timer-block wrapper--small bvi-no-styles">
    <img src="./assets/img/logo-main.svg" alt="Атомный диктант" class="logo bvi-no-styles">
    <!--<h4 class="period">{{'pages.main.period' | translate}}</h4>-->
    <ui-button class="custom" *ngIf="!(isAuthBtn$ | async)"  (click)="login()">{{ 'test.resultMain' | translate }}</ui-button>
    <ui-button class="custom" *ngIf="(isAuthBtn$ | async)" routerLink="test">{{ 'test.resultMain' | translate }}</ui-button>
    <!--*ngIf="!(isAuthBtn$ | async) && !isDictantAvailable"-->
    <!--<ui-button class="custom" *ngIf="!(isAuthBtn$ | async) && isDictantAvailable" (click)="reg()">{{ 'btn.startQuiz' | translate }}</ui-button>
    <ui-button class="custom" *ngIf="(isAuthBtn$ | async) && isDictantAvailable" routerLink="/dictation">{{ 'btn.startQuiz' | translate }}</ui-button>-->
    <div class="timer-entry bvi-no-styles">
      <div class="timer-entry__wrapper bvi-no-styles" *ngIf="timerInit$ | async">
        <div class="caption bvi-no-styles">{{ 'timer.caption' | translate}}</div>
        <div class="time-list bvi-no-styles">
          <div class="time-part bvi-no-styles" *ngIf="days$ | async as days">
            <div class="time-part__wrapper">
              <div class="value">{{ days }}</div>
              <div class="caption">{{ days | declensionDate: 'day' }}</div>
            </div>
          </div>
          <div class="time-part hour bvi-no-styles" *ngIf="hour$ | async as hour">
            <div class="time-part__wrapper">
              <div class="value">{{ hour }}</div>
              <div class="caption">{{ hour | declensionDate: 'hour' }}</div>
            </div>
          </div>
          <div class="time-part min bvi-no-styles" *ngIf="min$ | async as min">
            <div class="time-part__wrapper">
              <div class="value">{{ min }}</div>
              <div class="caption">{{ min | declensionDate: 'min' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="next-block" >
    <div class="icon-wrapper" (click)="nextWindow()">
      <div class="icon"></div>
    </div>
  </div>

</div>


<app-advantages-gpage *ngIf="videoAdvantages" [imageVideo]="videoAdvantages"></app-advantages-gpage>
<app-partner-slider [personList]="personList"></app-partner-slider>
<app-section
  class="section-events decoration decoration--image-left-1"
  style="--decoration-top-position: -178px"
>
  <ng-container head>
    <h2 class="section-title">{{ 'pages.main.events' | translate}}</h2>
    <div class="center">
      <ui-button type="primary" routerLink="events">{{ 'actionsBtn.readAllEvents' | translate}}</ui-button>
    </div>
  </ng-container>
  <ng-container body>
    <div class="event-list">
      <app-event-card
        *ngFor="let item of eventsList"
        [event]="item"
      ></app-event-card>
    </div>
  </ng-container>
</app-section>

<app-section
  [noWrapper]="true"
  class="section-partner decoration decoration--image-right-3"
  style="--decoration-top-position: -204px"
>
  <ng-container head>
    <h2 class="section-title">{{ 'pages.main.partners' | translate}}</h2>
    <div class="center">
      <ui-button type="primary" routerLink="partners">{{ 'actionsBtn.partners' | translate}}</ui-button>
    </div>
  </ng-container>
  <ng-container body *ngIf="partnersLogo.length">
    <div class="partners-list">
      <div class="embla__slide" *ngFor="let slide of partnersLogo">
        <div class="embla__image">
          <a [href]="slide.url" target="_blank">
            <img *ngIf="!!slide.get_thumb" [src]="environment.imageUrl + slide.get_thumb" alt="">
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</app-section>

<section class="content-wrapper question-section">
  <div class="section__body">
    <div class="question-head__wrapper">
      <h2 class="section-title" [innerHTML]="'pages.main.question' | translate"></h2>
    </div>
    <div class="section__content">
      <app-question-box [class.active]="faqService.isOpenFaq[item.id]" (openEvent)="setOpen(item.id)" [isOpen]="faqService.isOpenFaq[item.id]" [question]="item.question" *ngFor="let item of questionList; let i = index">
        <div [innerHTML]="item.answer"></div>
      </app-question-box>
    </div>
  </div>
  <div class="section__footer">
    <ui-button type="primary" routerLink="faq">{{ 'actionsBtn.allQuestions' | translate}}</ui-button>
  </div>
</section>

<app-section
  [noWrapper]="true"
  class="section-review decoration decoration--image-right-1"
  style="--decoration-top-position: -330px"
>
  <ng-container head>
    <h2 class="section-title">{{ 'pages.main.say' | translate }}</h2>
    <p class="pretitle">{{ 'pages.main.sayPretitle' | translate }}</p>
    <div class="arrows-area"></div>
  </ng-container>
  <ng-container body *ngIf="reviewList.length">
    <app-embla-slider
                    class="review-list"
                    [options]="reviewEmblaOptions"
                    [arrowVisible]="true">
      <div class="embla__slide" *ngFor="let item of reviewList">
        <app-review-item [active]="false" [address]="item.geo" [name]="item.title">
          <div [innerHTML]="item.text" ></div>
        </app-review-item>
      </div>
    </app-embla-slider>
  </ng-container>
  <ng-container footer>
    <ui-button type="primary" routerLink="reviews">{{ 'actionsBtn.readAllReviews' | translate}}</ui-button>
  </ng-container>
</app-section>

<app-section>
  <div head>
    <h2 class="section-title">{{ 'pages.main.projects' | translate}}</h2>
  </div>
  <div body>
    <div class="projects-list">
      <div class="projects-list__item" *ngFor="let item of projectsList">
        <img *ngIf="!!item.get_thumb" [src]="environment.imageUrl + item.get_thumb" alt="">
      </div>
    </div>
  </div>
</app-section>
