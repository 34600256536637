import { Injectable, Sanitizer } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMediaPhotos, IMediaVideo } from '../types';
import { IGallery } from '../types/response/gallery-image.interface';
import { environment } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class GalleryImageService {
  images$: BehaviorSubject<IGallery[]> = new BehaviorSubject<IGallery[]>([]);
  videos$: BehaviorSubject<IGallery[]> = new BehaviorSubject<IGallery[]>([]);
  stateVisible = new BehaviorSubject(false);
  type$ = new BehaviorSubject<'video' | 'image'>('image');
  openImageIndex = 0;
  constructor(private sanitizer: DomSanitizer) {}

  closeGallery() {
    this.stateVisible.next(false);
    this.images$.next([]);
    this.videos$.next([]);
    this.openImageIndex = 0;
  }

  openGalleryImages(images: IMediaPhotos[], index: number) {
    const gallery = images.map(item => {
      const image: IGallery = {
        link: environment.imageUrl + item.get_thumb,
        type: 'image',
        title: '',
      }
      return image;
    });
    this.images$.next(gallery);
    this.openImageIndex = index;
    this.type$.next('image');
    this.stateVisible.next(true);
  }

  openGalleryVideos(videos: IMediaVideo[], index: number) {
    const gallery = videos.map(item => {
      const video: IGallery = {
        link: item.link,
        type: 'video',
      }
      return video;
    });
    this.videos$.next(gallery);
    this.type$.next('video');
    this.openImageIndex = index;
    this.stateVisible.next(true);
  }
}
