import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-section',
  standalone: true,
  templateUrl: './section.component.html',
  imports: [
    NgClass,
  ],
  styleUrl: './section.component.scss',
})
export class SectionComponent {
  @Input() noWrapper = false;

}
