<footer class="footer">
  <div class="footer__wrapper content-wrapper">
    <div class="footer-nav">
      <nav class="navigation">
        <div class="navigation__item"><a routerLink="about">{{ 'navigations.projects' | translate }}</a></div>
        <div class="navigation__item"><a routerLink="faq">{{ 'navigations.question' | translate }}</a></div>
        <div class="navigation__item"><a routerLink="organizations">{{ 'navigations.org' | translate }}</a></div>
        <div class="navigation__item"><a routerLink="media-docs">{{ 'navigations.media' | translate }}</a></div>
        <div class="navigation__item"><a routerLink="partners">{{ 'navigations.partners' | translate }}</a></div>
        <div class="navigation__item"><a routerLink="events">{{ 'navigations.events' | translate }}</a></div>
        <div class="navigation__item"><a routerLink="reviews">{{ 'navigations.reviews' | translate }}</a></div>
      </nav>
      <div class="footer-action__social footer-action__social--mobile">
        <div class="social-list">
          <div class="item"><a class="vk" href="https://vk.com/atomgoroda" target="_blank"></a></div>
          <div class="item"><a class="tg" href="https://t.me/atomnyegoroda" target="_blank"></a></div>
        </div>
      </div>
      <div class="contacts">
        <div class="address">
          <div class="caption">{{ 'footer.contactData' | translate}}</div>
          <div class="value"><a href="https://yandex.ru/maps/-/CDgCmRKp" target="_blank" [innerHTML]="'footer.address' | translate">Москва, <br>1-я Тверская-Ямская д. 25с2</a></div>
        </div>
        <div class="address">
          <div class="caption">{{ 'footer.writeToUse' | translate}}</div>
          <div class="value"><a href="mailto:info@atomdiktant.ru">info&#64;atomdiktant.ru</a></div>
        </div>
      </div>
    </div>
    <div class="footer-action">
      <div class="footer-action__link">
      <div class="political">© «{{ 'copyright' | translate }}» 2021—2024</div>
      <a class="support" target="_blank" href="https://t.me/Atomdiktant">{{ 'navigations.support' | translate }}</a>
      <a routerLink="political">{{ 'navigations.political' | translate }}</a>
      </div>
      <div class="footer-action__social">
        <div class="social-list">
          <div class="item"><a class="vk" href="https://vk.com/atomgoroda" target="_blank"></a></div>
          <div class="item"><a class="tg" href="https://t.me/atomnyegoroda" target="_blank"></a></div>
        </div>
      </div>
    </div>
  </div>
</footer>
