import {Component, Inject, OnInit, Self} from '@angular/core';
import { ApiService } from '../../service/api.service';
import { IFiles } from '../../types/response/files.interface';
import { IMediaPhotos, IMediaProjects, IMediaVideo } from '../../types/response/media.interface';
import { environment } from '../../../environments/environment';
import { GalleryImageService } from '../../service/gallery.service';
import {ActivatedRoute} from "@angular/router";
import {LangService} from "../../service/lang.service";
import {DestroyService} from "../../service/destroy.service";
import {Observable, takeUntil} from "rxjs";

@Component({
  selector: 'app-page-media',
  templateUrl: './media.component.html',
  styleUrl: './media.component.scss',
  providers: [DestroyService],
})
export class MediaComponent implements OnInit {
  filesList: IFiles[] = [];
  projectsList: IMediaProjects[] = [];
  galleryImages: IMediaPhotos[] = [];
  galleryVideo: IMediaVideo[] = [];

  constructor(private apiService: ApiService,
              private galleryService: GalleryImageService,
              private activatedRoute: ActivatedRoute,
              @Self() @Inject(DestroyService) private destroy$: Observable<void>) {
    this.apiService.getMediaDocuments().subscribe(files => {
      this.filesList = files;
    });

    this.apiService.getProjects().subscribe(projects => {
      this.projectsList = projects.slice(1, projects.length);
    });
    this.apiService.getMediaPhotos().subscribe(photos => {
      this.galleryImages = photos;
    });
    this.apiService.getMediaVideos().subscribe(videos => {
      this.galleryVideo = videos;
    });
  }

  ngOnInit() {
    this.activatedRoute.fragment.pipe(takeUntil(this.destroy$)).subscribe((fragment: string | null) => {
      if (fragment) this.jumpToSection(fragment);
    });
  }

  jumpToSection(section: string | null) {
    if (section) {
      document.querySelector('html')?.scrollTo({top: document.getElementById(section)?.offsetTop, behavior: "smooth"});
    }
  }

  protected readonly environment = environment;

  openGallery(galleryImages: IMediaPhotos[] | IMediaVideo[], index: number, type: 'video' | 'image') {
    if (type === 'video') {
      const gallery = galleryImages as IMediaVideo[];
      this.galleryService.openGalleryVideos(gallery, index);
    }
    if (type === 'image') {
      const gallery =  galleryImages as IMediaPhotos[];
      this.galleryService.openGalleryImages(gallery, index);
    }
  }
}
