<div class="content-wrapper" >
  <div class="advantages">
    <div class="content">
      <div class="video" (click)="playVideo()">
        <div class="video-wrapper">
          <div class="icon-play"></div>
          <img class="iframe" [src]="environment.imageUrl + imageVideo.get_thumb" alt="">
        </div>
      </div>
      <div class="advantages-list">
        <div class="advantages-item">
          <div class="advantages-entry">
            <div class="alt">30</div>
            <div class="caption" [innerHtml]="'advantages.question' | translate"></div>
          </div>
        </div>
        <div class="advantages-item">
          <div class="advantages-entry">
            <div class="caption" [innerHtml]="'advantages.online' | translate"></div>
          </div>
        </div>
        <div class="advantages-item">
          <div class="advantages-entry">
            <div class="alt">>20 000</div>
            <div class="caption" [innerHtml]="'advantages.winners' | translate"></div>
          </div>
        </div>
        <div class="advantages-item">
          <div class="advantages-entry">
            <div class="caption" [innerHtml]="'advantages.sert' | translate"></div>
          </div>
        </div>
        <div class="advantages-item">
          <div class="advantages-entry">
            <div class="alt">8 000</div>
            <div class="caption" [innerHtml]="'advantages.smi' | translate"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
