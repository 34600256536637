import { Component } from '@angular/core';
import {ApiService} from "../../service/api.service";
import { IFaq } from '../../types';
import {FaqService} from "../../service/faq.service";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {

  questionList: IFaq[] = [];

  constructor(private apiService: ApiService, public faqService: FaqService) {
    this.apiService.getFaq().subscribe(question => {
      this.faqService.init(question);
      this.questionList = question;
    });
  }

  setOpen(id: number) {
    this.faqService.setOpen(id);
  }
}
