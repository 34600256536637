import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UITimerButtonComponent } from './ui-timer-button.component';
import {SecondsToMinutesPipe} from "../../pipe/seconds-to-minutes.pipe";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
    declarations: [
      UITimerButtonComponent,
      SecondsToMinutesPipe
    ],
    exports: [UITimerButtonComponent],
})
export class UiTimerButtonModule {}
