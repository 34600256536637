<app-frame-page [titlePage]="'pages.mediaDocs.title' | translate"  class="decorationFull">
  <ng-container>
    <div class="wrapper-1122">
      <aside>
        <div class="aside-item" *ngIf="filesList.length">
          <a (click)="jumpToSection('doc')" class="aside-action">{{'pages.mediaDocs.actions.documents'  | translate}}</a>
        </div>
        <div class="aside-item">
          <a (click)="jumpToSection('photo')" class="aside-action">{{'pages.mediaDocs.actions.photo'  | translate}}</a>
        </div>
        <div class="aside-item">
          <a (click)="jumpToSection('video')" class="aside-action">{{'pages.mediaDocs.actions.video' | translate}}</a>
        </div>
        <div class="aside-item">
          <a (click)="jumpToSection('projects')" class="aside-action">{{'pages.mediaDocs.actions.otherProjects'  | translate}}</a>
        </div>
      </aside>
      <div class="content">
        <section id="doc" *ngIf="filesList.length">
          <h2 class="h3 files-caption section-caption">{{'pages.mediaDocs.actions.documents'  | translate}}</h2>
          <app-files [files]="filesList"></app-files>
        </section>
        <section  id="video">
          <h2 class="h3 section-caption">{{'pages.mediaDocs.actions.video'  | translate}}</h2>
          <div class="projects-list projects-list--video">
            <div class="projects-list__item" *ngFor="let item of galleryVideo; let index = index">
              <img class="action-gallery" *ngIf="!!item.get_thumb" [src]="environment.imageUrl + item.get_thumb" (click)="openGallery(galleryVideo, index, 'video')" alt="">
            </div>
          </div>
        </section>
        <section id="photo">
          <h2 class="h3 section-caption">{{'pages.mediaDocs.actions.photo'  | translate}}</h2>
          <div class="projects-list">
            <div class="projects-list__item" *ngFor="let item of galleryImages; let index = index">
              <img class="action-gallery" *ngIf="!!item.get_thumb" [src]="environment.imageUrl + item.get_thumb" (click)="openGallery(galleryImages, index, 'image')" alt="">
            </div>
          </div>
        </section>
        <section id="projects">
          <h2 class="h3 section-caption">{{'pages.mediaDocs.actions.otherProjects'  | translate}}</h2>
          <div class="projects-list projects-list--photo">
            <div class="projects-list__item" *ngFor="let item of projectsList">
              <!--<a [href]="item.url" target="_blank">-->
                <img class="action-gallery" *ngIf="!!item.get_thumb" [src]="environment.imageUrl + item.get_thumb" alt="">
              <!--</a>-->
            </div>
          </div>
        </section>
      </div>
    </div>
  </ng-container>
</app-frame-page>
