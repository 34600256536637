<app-frame-page [titlePage]="'navigations.projects' | translate" class="decorationFull decoration--image-left-1">
  <ng-container>
    <div class="wrapper--small">
      <div>
        <img class="logo" src="assets/img/logo-about.svg">
        <div [innerHTML]="'pages.about.text.text' | translate"></div>
      </div>
    </div>
    <div class="advantages">
      <div class="item">
        <div class="item__wrapper h4" [innerHTML]="'pages.about.text.list1' | translate"></div>
      </div>
      <div class="item">
        <div class="item__wrapper h4" [innerHTML]="'pages.about.text.list2' | translate"></div>
      </div>
      <div class="item">
        <div class="item__wrapper h4" [innerHTML]="'pages.about.text.list3' | translate"></div>
      </div>
      <div class="item">
        <div class="item__wrapper h4" [innerHTML]="'pages.about.text.list4' | translate"></div>
      </div>
      <div class="item">
        <div class="item__wrapper h4" [innerHTML]="'pages.about.text.list5' | translate"></div>
      </div>
    </div>
    <div class="wrapper--small">
      <div class="trueAnswer">
        <img [src]="'pages.about.image' | translate">
        <div class="content">
          <p [innerHTML]="'pages.about.text.answer' | translate"></p>
          <p class="h4" [innerHTML]="'pages.about.text.answerTitle' | translate"></p>
        </div>
      </div>
      <div class="files" *ngIf="filesList.length">
        <h3 class="h3 files-caption">{{'other.documentDownload' | translate}}</h3>
        <app-files [files]="filesList"></app-files>
      </div>
    </div>
  </ng-container>
</app-frame-page>
