import {AfterViewInit, Component, OnInit} from '@angular/core';
import { GalleryImageService } from './service/gallery.service';
import { PropsService } from './service/props.service';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {AuthModalService} from "./service/auth-modal.service";
import {AuthService} from "./service/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {DictantService} from "./service/dictant.service";
import {LangService} from "./service/lang.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'atom-dict';
  headerNotFix = this.propsService.headerNotFixed;
  activeCookie = false;
  loadedInfo = false;
  constructor(public galleryService: GalleryImageService,
              private propsService: PropsService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private dictantService: DictantService,
              public authModal: AuthModalService,
              private languageService: LangService,
              private translate: TranslateService,
              private titleService: Title,
              private metaService: Meta) {
    if (!this.loadedInfo && this.authService.isAuthenticated()) {
      this.dictantService.checkStartDictant().subscribe(x => {
        this.loadedInfo = true;
      });
    }
    this.languageService.currentLanguage$.subscribe(lang => {
      this.translate.use(lang);
    }); // Устанавливаем язык по умолчанию
    if (this.authService.isAuthenticated()) {
      // this.dictionaryService.init();
      // this.authService.nameOrg = localStorage.getItem('orgName');
      this.authService.profileInit();
    }

  }

  ngAfterViewInit(): void {
        this.route.queryParams.subscribe((params) => {
          if (this.route.snapshot.queryParams['restore_token']?.length) {
            this.authModal.isOpenRecoveryPasswordModal$.next(this.route.snapshot.queryParams['restore_token']);
          }
        });
    }

  ngOnInit() {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = this.router.url;
        if (url === '/') {
          this.updateMeta('Атомный Диктант от Союза «Атомные города»', 'Союз «Атомные города» при поддержке Госкорпорации «Росатом» приглашает Вас принять участие в «Атомном Диктанте».')
          return;
        }
        if (url === '/media-docs') {
          this.updateMeta('Медиа', '"Атомный диктант" в  выпуске программы "Доброе утро" на Первом канале');
          return;
        }
        if (url === '/organizations') {
          this.updateMeta('Организатор', 'Союз организаций атомной отрасли «Атомные города» – объединение предприятий атомного энергопромышленного комплекса России.');
          return;
        }
        if (url === '/political') {
          this.updateMeta('Политика конфиденциальности', 'Союз «Атомные города» при поддержке Госкорпорации «Росатом» приглашает Вас принять участие в «Атомном Диктанте».');
          return;
        }
        if (url === '/about') {
          this.updateMeta('О проекте', 'Международный просветительский проект, ориентированный на популяризацию атомной отрасли и формирование образа доступных естественно-научных знаний.');
          return;
        }
        if (url === '/events') {
          this.updateMeta('События', 'Новости атомной промышленности');
          return;
        }
        if (url.includes('/events/')) {
          this.updateMeta('События', 'Новости атомной промышленности');
          return;
        }
        if (url === '/partners') {
          this.updateMeta('Партнеры','Более 20 партнеров');
          return;
        }
        if (url === '/reviews') {
          this.updateMeta('Отзывы', 'Более 10 тысяч отзывов');
          return;
        }
        if (url === '/faq') {
          this.updateMeta('Вопросы', 'Когда можно пройти Атомный диктант?\n' +
              'Пройти Диктант можно с 1 по 14 сентября 2024 года.');
          return;
        }
        if (url === '/dictation') {
          this.updateMeta('Атомный Диктант от Союза «Атомные города»', 'Союз «Атомные города» при поддержке Госкорпорации «Росатом» приглашает Вас принять участие в «Атомном Диктанте».');
          return;
        }
        if (url === '/dictation/questions') {
          this.updateMeta('Атомный Диктант от Союза «Атомные города»', 'Союз «Атомные города» при поддержке Госкорпорации «Росатом» приглашает Вас принять участие в «Атомном Диктанте».');
          return;
        }
        if (url === '/dictation/end') {
          this.updateMeta('Атомный Диктант от Союза «Атомные города»', 'Союз «Атомные города» при поддержке Госкорпорации «Росатом» приглашает Вас принять участие в «Атомном Диктанте».');
          return;
        }
        if (url === '/profile') {
          this.updateMeta('Личный кабинет', 'Союз «Атомные города» при поддержке Госкорпорации «Росатом» приглашает Вас принять участие в «Атомном Диктанте».');
          return;
        }
        if (url === '/profile/dictation') {
          this.updateMeta('Личный кабинет', 'Союз «Атомные города» при поддержке Госкорпорации «Росатом» приглашает Вас принять участие в «Атомном Диктанте».');
          return;
        }
        if (url === '/profile/statistics') {
          this.updateMeta('Личный кабинет', 'Союз «Атомные города» при поддержке Госкорпорации «Росатом» приглашает Вас принять участие в «Атомном Диктанте».');
          return;
        }
      }
    });


    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
        window.scrollTo(0, 0);
    });
    setTimeout(() => {
      if (!localStorage.getItem('cookie-req-atom')?.length) {
        this.activeCookie = true;
      }
    }, 4000);

  }

  updateMeta(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ property: 'og:description', content: description });
    return;
  }

  req() {
    localStorage.setItem('cookie-req-atom', 'true');
    this.activeCookie = false;
  }
}
