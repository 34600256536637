import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {enableProdMode} from "@angular/core";
import {environment} from "./environments/environment";
import {AppModule} from "./app/app.module";
import * as Sentry from "@sentry/angular";


Sentry.init({
  dsn: "https://c8c838307ff664a05b49e7ef29f0616c@o4506773657354240.ingest.us.sentry.io/4507923103285248",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  environment: environment.production ? 'production' : 'staging',
  tracePropagationTargets: ["localhost", environment.apiUrl],
  /* /^https:\/\/api\.atomdiktant\.ru\/api/, /^https:\/\/atomdiktant\.ru\/api/, */
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err) => console.error(err));
