import { Component } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { ActivatedRoute } from '@angular/router';
import { mergeMap } from 'rxjs';
import { IEventDetail } from '../../types';

@Component({
  selector: 'app-page-events-detail',
  templateUrl: './events-detail.component.html',
  styleUrl: './events-detail.component.scss'
})
export class EventsDetailComponent {
  event!: IEventDetail;
  loaded = false;
  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute) {
  }
  ngOnInit() {
    this.activatedRoute.params.pipe(mergeMap(({slug}) => {
      return this.apiService.getEventsDetail(slug);
    })).subscribe(event => {
      this.loaded = true;
      this.event = event;
    });
  }
}
