import { Component, Input } from '@angular/core';
import { NgForOf, NgIf, NgStyle } from "@angular/common";
import { GalleryImageService } from '../../service/gallery.service';
import { IMediaVideo, IVideoMainPageAdvantages } from '../../types';
import { environment } from '../../../environments/environment';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-advantages-gpage',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgStyle,
    TranslateModule
  ],
  templateUrl: './advantages.component.html',
  styleUrl: './advantages.component.scss'
})
export class AdvantagesComponent {
  @Input() imageVideo!: IVideoMainPageAdvantages;

  constructor(private galleryService: GalleryImageService) {
  }
  playVideo() {
    if (!this.imageVideo) {
      return;
    }
    const mediaVideo: IMediaVideo = {
      link: this.imageVideo.url,
      get_thumb: this.imageVideo.get_thumb,
      id: this.imageVideo.id,
    }
    this.galleryService.openGalleryVideos([mediaVideo], 0);
  }

  protected readonly environment = environment;
}
