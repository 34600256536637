import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor, HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import {LangService} from "../service/lang.service";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable()
export class LangInterceptor implements HttpInterceptor {
    constructor(private langService: LangService, private route: ActivatedRoute,
                private router: Router) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
      const lang = this.langService.currentLang;
      // this.langService.setLanguage((window.location.href.indexOf('/en') + 1) ? 'en' : 'ru');
      if (!req.url.includes('language')) {
        const newReq = req.clone({
        params: (req.params ? req.params : new HttpParams())
                     .set('language', (window.location.pathname.startsWith('/en')) ? 'en' : 'ru')
        });
        return next.handle(newReq);
      }
      return next.handle(req);
    }
}
