import { Component } from '@angular/core';
import {ApiService} from "../../service/api.service";
import { IReview } from '../../types/response/review.interface';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrl: './reviews.component.scss'
})
export class ReviewsComponent {

  reviewsList: IReview[] = [];

  pageMore!: number;
  nextUrl: string = '';
  visibleMore = false;

  paginationReady = false;
  totalItems: number = 0;
  itemsPerPage: number = 15; // сколько от выводить
  currentPage$ = new BehaviorSubject<number>(1);


  constructor(private apiService: ApiService) {

  }

  ngOnInit() {
    this.loadReviews(1);
  }

  loadReviews(page: number, isMore = false) {
    const offset = (page - 1) * this.itemsPerPage;
    this.apiService.getResponses(this.itemsPerPage, offset).subscribe(reviews => {
      this.reviewsList = isMore ? [...this.reviewsList, ...reviews.results] : reviews.results;
      this.pageMore = reviews.count - reviews.results.length;
      this.nextUrl = reviews.next;
      this.visibleMore = this.reviewsList.length < reviews.count;

      this.totalItems = reviews.count;
      this.currentPage$.next(page);
      this.paginationReady = true;
    });
  }

  onPageChanged(page: number): void {
    this.loadReviews(page);
  }

  onPageMore(page: number): void {
    this.loadReviews(page, true);
  }

}
