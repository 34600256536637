<section class="section">
  <div class="section__wrapper" [class.content-wrapper]="!noWrapper">
    <div class="section__head section-center">
      <ng-content select="[head]"></ng-content>
    </div>
    <div class="section__body">
      <ng-content  select="[body]"></ng-content>
    </div>
    <div class="section__footer section-center">
      <ng-content  select="[footer]"></ng-content>
    </div>
  </div>
</section>
