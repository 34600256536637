<div class="gallery">
  <!-- Большое изображение -->
  <div class="large-image">
    <img [src]="environment.imageUrl + images[selectedIndex].get_thumb" [alt]="images[selectedIndex].title" />
    <ui-button typeButton="slider-action" class="prev-button" (click)="prevImage()">&#10094;</ui-button>
    <ui-button typeButton="slider-action" class="next-button" (click)="nextImage()">&#10095;</ui-button>
  </div>
  <p *ngIf="images[selectedIndex].title">{{images[selectedIndex].title}}</p>

  <!-- Слайдер с миниатюрами -->
  <div class="thumbnails" emblaCarousel #embla="emblaCarousel">
    <div class="embla__container">
      <div class="embla__slide" *ngFor="let image of images; let i = index" (click)="selectImage(i)">
        <img [src]="environment.imageUrl + image.get_thumb_small" [class.active]="i === selectedIndex" />
      </div>
    </div>
  </div>
</div>
