<app-frame-page titlePage="Страница не найдена" class="decoration">
  <ng-container>
    <div class="wrapper--small">
      <div class="wrapper-404">
        <div>
          <h2 class="h4 pretitle">Страница, которую вы запросили, отсутствует на нашем сайте. </h2>
          <p>Возможно, вы ошиблись при наборе адреса или перешли по неверной ссылке.</p>
          <ui-button type="primary" routerLink="/">На главную</ui-button>
        </div>
      </div>
    </div>
  </ng-container>
</app-frame-page>


