import { Component } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { IPageInfo } from '../../types/response/page.interface';

@Component({
  selector: 'app-political',
  templateUrl: './political.component.html',
  styleUrl: './political.component.scss'
})
export class PoliticalComponent {
  pageContent!: IPageInfo | null | undefined;
  constructor(private apiService: ApiService) {
    this.apiService.getPageSlug('politika-konfidencialnosti').subscribe(x => {
      this.pageContent = x;
    })
  }

}
