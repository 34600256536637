<app-frame-page [titlePage]="'navigations.events' | translate" class="decorationFull">
  <ng-container>
    <div class="event-list">
      <app-event-card *ngFor="let item of eventsList; let i = index;"
        [event]="item"
      ></app-event-card>
    </div>
  </ng-container>
  <ng-container footer>
    <app-pagination
      *ngIf="paginationReady && itemsPerPage <= totalItems"
      [totalItems]="totalItems"
      [itemsPerPage]="itemsPerPage"
      [currentPage$]="currentPage$"
      (pageChanged)="onPageChanged($event)"
      (pageMore)="onPageMore($event)"
    >
    </app-pagination>
  </ng-container>
</app-frame-page>



