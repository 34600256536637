import { Component } from '@angular/core';
import {ApiService} from "../../service/api.service";
import { IMediaPartners } from '../../types/response/media.interface';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-page-partners',
  templateUrl: './partners.component.html',
  styleUrl: './partners.component.scss'
})
export class PartnersComponent {
  partnersList: IMediaPartners[] = [];
  constructor(private apiService: ApiService) {
    this.apiService.getPartners().subscribe(partners => {
      this.partnersList = partners;
    })
  }

  protected readonly environment = environment;
}
