import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {NgIf} from "@angular/common";
import { animate, style, transition, trigger } from "@angular/animations";
import { AnimateHeightDirective } from '../../directive/animate-height.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const smoothHeight = trigger('grow', [
  transition('void <=> *', []),
  transition('* <=> *', [style({ height: '{{startHeight}}px' }), animate('.5s ease')], {
    params: { startHeight: 0 }
  })
]);

@Component({
  selector: 'app-question-box',
  standalone: true,
  templateUrl: './question-box.component.html',
  animations: [smoothHeight],
  imports: [
    BrowserAnimationsModule,
    NgIf,
    AnimateHeightDirective
  ],
  styleUrl: './question-box.component.scss'
})
export class QuestionBoxComponent {

  @Input() isOpen = false;
  @Input() question = '';

  @Output() openEvent = new EventEmitter();
  @HostBinding('class.active') active: boolean = this.isOpen;
  constructor() {
  }

  setState() {
    this.openEvent.emit();
    /*this.isOpen = !this.isOpen;
    this.active = this.isOpen;*/
  }
}
