import { Pipe, PipeTransform } from '@angular/core';
import {LangService} from "../service/lang.service";

@Pipe({
  name: 'declensionDate',
  standalone: true
})
export class DeclinsionPipe implements PipeTransform {

  day= {
    ru: ['день', 'дня', 'дней'],
    en: ['day', 'day', 'days']
  };

  hour= {
    ru: ['час', 'часа', 'часов'],
    en: ['hour', 'hours', 'hours']
  }

  min= {
    ru: ['минута', 'минуты', 'минут'],
    en: ['minute', 'minutes', 'minutes']
  }

  review = {
    ru: ['отзыв', 'отзыва', 'отзывов'],
    en: ['review', 'review', 'reviews`'],
  }

  constructor(private langService: LangService) {

  }


  transform(value: string | number, type: 'day' | 'min' | 'hour' | 'review'): string {
    return this.declensionNum(value, type);
  }

  getWords(type: 'day' | 'min' | 'hour' | 'review') {
    const currentLang = window.location.href.includes('/en') ? 'en' : 'ru';
    if (type === 'day') {
      return currentLang === 'en' ? this.day.en : this.day.ru;
    }
    if (type === 'min') {
      return currentLang === 'en' ? this.min.en : this.min.ru;
    }
    if (type === 'review') {
      return currentLang === 'en' ? this.review.en : this.review.ru;
    }
    return currentLang === 'en' ? this.hour.en : this.hour.ru;
  }

  declensionNum(num: string | number, type: 'day' | 'min' | 'hour' | 'review') {
    const number = Number(num);
    return this.getWords(type)[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]];
  }
}
