import {
  ControlValueAccessor,
  AbstractControl,
  NgControl,
  ValidationErrors,
  ValidatorFn,
  FormControl
} from '@angular/forms';
import {Input, Optional, Self, Host, Inject, OnInit, OnDestroy, Directive, ViewChild, ElementRef} from '@angular/core';
import { UI_INPUT_VALIDATION_ERROR_TOKEN } from './ui-input-validation-error-token';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class UIBaseControlComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() label = '';
    @Input() hint = '';
    @Input() placeholder = '';
    formControl: any;

    errors!: ValidationErrors;

    initialValidator!: ValidatorFn;
    validators: ValidatorFn[] = [];

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        @Optional()
        @Host()
        @Inject(UI_INPUT_VALIDATION_ERROR_TOKEN)
        public customErrors: ValidationErrors
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit() {
        this.errors = { ...this.customErrors, ...this.errors };

        if (!this.formControl) {
            this.formControl = (this.ngControl && this.ngControl.control) as AbstractControl;
        }

        this.initialValidator = (this.formControl.validator) as ValidatorFn;
        // @ts-ignore
      this.formControl.setValidators([this.formControl.validator, ...this.validators].filter(Boolean));
        this.formControl.updateValueAndValidity();
    }

    ngOnDestroy(): void {
        this.formControl.setValidators(this.initialValidator);
        this.formControl.updateValueAndValidity();
    }

    writeValue(obj: any): void {
        // if (this.formControl) {
        //     this.formControl.setValue(obj);
        // }
    }

    onChange: any = (_: any) => {};
    onTouched: any = () => {};

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
