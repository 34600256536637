import { Component } from '@angular/core';
import { PropsService } from '../../service/props.service';
import {AuthModalService} from "../../service/auth-modal.service";
import {AuthService} from "../../service/auth.service";
import {LangService} from "../../service/lang.service";
import {Router} from "@angular/router";
import {map} from "rxjs";


@Component({
  selector: 'app-header',
 /* standalone: true,
  imports: [],*/
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  stateMenu = false;
  isAuth = this.authService.isAuthenticated$;
  langOther$ = this.languageService.currentLanguage$.pipe(map(lang => {
    return lang === 'en' ? 'RUS' : 'ENG';
  }));
  dictationPage$ = this.authService.dictationPage$;

  constructor(private propsService: PropsService,
              private authService: AuthService,
              private authModalService: AuthModalService,
              public languageService: LangService,
              private router: Router) {
    this.isAuth.subscribe(x => {
      this.closeMenu();
    })
  }
  setMenu() {
    this.stateMenu = !this.stateMenu;
    if (this.stateMenu) {
      // @ts-ignore
      document.querySelector('body').classList.add('hide') } else { document.querySelector('body').classList.remove('hide') }
    this.propsService.stateMenu$.next(this.stateMenu);
  }

  closeMenu() {
    this.stateMenu = false;
    this.propsService.stateMenu$.next(this.stateMenu);
    // @ts-ignore
    document.querySelector('body').classList.remove('hide');
  }

  login() {
    this.authModalService.isOpen$.next(true);
  }

  bviOpen() {
    if (document.querySelector('body')?.classList.value === 'bvi-active') {
      // @ts-ignore
      document.querySelector('a[data-bvi="close"]')?.click();
      return;
    }
    // @ts-ignore
    document.querySelector('.bvi-open').click();
  }

  switchLanguage() {
    const currentLang = this.languageService.getLanguage();
    const newLang = currentLang === 'ru' ? 'en' : 'ru';
    this.languageService.setLanguage(newLang);

    // Получаем текущий URL и обновляем его в соответствии с новым языком
    let currentUrl = this.router.url;

    if (newLang === 'en' && !currentUrl.startsWith('/en')) {
      // Если переключаем на английский и текущий URL не содержит '/en'
      currentUrl = '/en' + (currentUrl === '/' ? '' : currentUrl);
    } else if (newLang === 'ru' && currentUrl.startsWith('/en')) {
      // Если переключаем на русский и текущий URL содержит '/en'
      currentUrl = currentUrl.replace('/en', '');
    }

    this.router.navigateByUrl(currentUrl, { replaceUrl: true });
  }
}
