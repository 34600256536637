import { Component, Input, OnInit, Sanitizer } from '@angular/core';
import { UiModule } from '../../ui/ui.module';
import { IGallery } from '../../types/response/gallery-image.interface';
import { GalleryImageService } from '../../service/gallery.service';
import { NgForOf, NgIf } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-gallery-image',
  templateUrl: './gallery-image.component.html',
  standalone: true,
  imports: [
    UiModule,
    NgIf,
    NgForOf,
  ],
  styleUrls: [ './gallery-image.component.scss' ],
})
export class GalleryImageComponent implements OnInit {

  @Input() moderation = false;
  @Input() images: IGallery[] = [];
  @Input() videos: IGallery[] = [];
  @Input() type: 'video' | 'image' = 'image';
  currentIndex = 0;

/*  get urlVideo(): SafeResourceUrl {
    return this.videos[this.currentIndex].link);
  }*/
  constructor(private galleryService: GalleryImageService, private sanitizer: DomSanitizer ) { }

  ngOnInit(): void {
    this.type = this.galleryService.type$.getValue();
    this.images = this.galleryService.images$.getValue().map(x => {
      const link = x.link as string;
      x.link = this.sanitizer.bypassSecurityTrustResourceUrl(link);
      return x;
    }) ;
    this.videos = this.galleryService.videos$.getValue().map(x => {
      const link = x.link as string;
      x.link = this.sanitizer.bypassSecurityTrustResourceUrl(link);
      return x;
    });
    this.currentIndex = this.galleryService.openImageIndex;
  }

  nextImage() {
    let indexTarget = this.currentIndex + 1;
    const max = this.type === 'image' ? this.images.length : this.videos.length;
    if (max <= indexTarget) {
      indexTarget = 0;
      // return;
    }
    this.currentIndex = indexTarget;
  }

  prevImage() {
    let indexTarget = this.currentIndex - 1;
    if (0 > indexTarget) {
      indexTarget = this.images.length - 1;
    }
    this.currentIndex = indexTarget;
  }

  close() {
    this.galleryService.closeGallery();
  }
}
