import {forwardRef, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import { UIInputPhoneComponent } from './ui-input-phone.component';
import {MaskitoDirective} from "@maskito/angular";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        MaskitoDirective,
        ReactiveFormsModule,
        TranslateModule,
    ],
  declarations: [UIInputPhoneComponent],
  exports: [UIInputPhoneComponent],
  providers: [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UIInputPhoneComponent),
    multi: true,
  }
]
})
export class UIInputPhoneModule {}
