import { Routes } from '@angular/router';
import {MainComponent} from "./pages/main/main.component";
import {PartnersComponent} from "./pages/partners/partners.component";
import {EventsComponent} from "./pages/events/events.component";
import {AboutComponent} from "./pages/about/about.component";
import {MediaComponent} from "./pages/media/media.component";
import {FaqComponent} from "./pages/faq/faq.component";
import {ReviewsComponent} from "./pages/reviews/reviews.component";
import {OrganizersComponent} from "./pages/organizers/organizers.component";
import {PoliticalComponent} from "./pages/political/political.component";
import { EventsDetailComponent } from './pages/events-detail/events-detail.component';
import {AuthGuard} from "./guard/auth.guard";
import {Error404Component} from "./pages/404/404.component";
import {LanguageGuard} from "./guard/lang.guard";
import {DictantAvailableGuard} from "./guard/dictant-available.guard";

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'profile',
    canActivate: [LanguageGuard, AuthGuard],
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  /*{
    path: 'dictation',
    canActivate: [DictantAvailableGuard, AuthGuard],
    loadChildren: () => import('./pages/dictant/dictant.module').then(m => m.DictantModule)
  },*/
  // complete
  {
    path: 'test',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/test/test.module').then(m => m.TestModule)
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'reviews',
    component: ReviewsComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'partners',
    component: PartnersComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'political',
    component: PoliticalComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'organizations',
    component: OrganizersComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'media-docs',
    component: MediaComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'about',
    component: AboutComponent,
    canActivate: [LanguageGuard],
  },

  // in work
  {
    path: 'events/:slug',
    component: EventsDetailComponent,
    canActivate: [LanguageGuard],
  },
  {
    path: 'en/',
    redirectTo: 'en',
  },
  {
    path: 'en',
    children: [
      {
        path: '',
        component: MainComponent,
        canActivate: [LanguageGuard],
        data: { language: 'en' }
      },
      {
        path: 'events',
        component: EventsComponent,
        canActivate: [LanguageGuard],
        data: { language: 'en' }
      },
      /*{
        path: 'dictation',
        canActivate: [DictantAvailableGuard, AuthGuard],
        canActivateChild: [DictantAvailableGuard],
        loadChildren: () => import('./pages/dictant/dictant.module').then(m => m.DictantModule)
      },*/
      {
        path: 'test',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/test/test.module').then(m => m.TestModule)
      },
      {
        path: 'profile',
        canActivate: [LanguageGuard, AuthGuard],
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'reviews',
        component: ReviewsComponent,
        canActivate: [LanguageGuard],
        data: { language: 'en' }
      },
      {
        path: 'partners',
        component: PartnersComponent,
        canActivate: [LanguageGuard],
        data: { language: 'en' }
      },
      {
        path: 'political',
        component: PoliticalComponent,
        canActivate: [LanguageGuard],
        data: { language: 'en' }
      },
      {
        path: 'organizations',
        component: OrganizersComponent,
        canActivate: [LanguageGuard],
        data: { language: 'en' }
      },
      {
        path: 'faq',
        component: FaqComponent,
        canActivate: [LanguageGuard],
        data: { language: 'en' }
      },
      {
        path: 'media-docs',
        component: MediaComponent,
        canActivate: [LanguageGuard],
        data: { language: 'en' }
      },
      {
        path: 'about',
        component: AboutComponent,
        canActivate: [LanguageGuard],
        data: { language: 'en' }
      },

      // in work
      {
        path: 'events/:slug',
        component: EventsDetailComponent,
        canActivate: [LanguageGuard],
        data: { language: 'en' }
      },
    ]
  },
  {
    path: '**',
    component: Error404Component,
  },

];
