import {Component, OnDestroy} from '@angular/core';
import {Meta} from "@angular/platform-browser";

@Component({
  templateUrl: './404.component.html',
  styleUrl: './404.component.scss'
})
export class Error404Component implements OnDestroy {
  constructor(private meta: Meta) {}

  ngOnInit(): void {
    // Устанавливаем мета-теги для исключения индексации
    this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
  }

  ngOnDestroy() {
    this.meta.removeTag("name='robots'");
  }

}
