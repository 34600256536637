import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { IEvent } from '../../types';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-page-events',
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss'
})
export class EventsComponent implements OnInit {
  eventsList: IEvent[] = [];
  nextUrl: string = '';
  visibleMore = false;


  paginationReady = false;
  totalItems: number = 0;
  itemsPerPage: number = 15; // сколько от выводить
  currentPage$ = new BehaviorSubject<number>(1);

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.loadEvents(1);
  }

  loadEvents(page: number, isMore = false) {
    const offset = (page - 1) * this.itemsPerPage;
    this.apiService.getEvents(this.itemsPerPage, offset).subscribe(events => {
      this.eventsList = isMore ? [...this.eventsList, ...events.results] : events.results;
      this.nextUrl = events.next;
      this.visibleMore = this.eventsList.length < events.count;

      this.totalItems = events.count;
      this.currentPage$.next(page);
      this.paginationReady = true;
    });
  }

  onPageChanged(page: number): void {
    this.loadEvents(page);
  }

  onPageMore(page: number): void {
    this.loadEvents(page, true);
  }
}
